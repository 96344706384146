html {
  font-size: 62.5%;
}

@media screen and (max-width: 960px) {
  html {
    font-size: 56.25%;
  }
}

@media screen and (max-width: 600px) {
  html {
    font-size: 50%;
  }
}
